/*
@import "_config_tailwind.scss";@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";*/

/*@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/public/assets/font/Inter-roman.var2.woff2) format('woff2');
}*/

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 100 900;
  src: url(/public/assets/font/Inter.woff2) format("woff2")
}

/*overflow-y-scroll */

body {
  width: 100%;
  font-family: Inter,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-feature-settings: "cv02","cv03","cv04","cv11"
}

.content-width-small {
  max-width: 800px
}

.content-width-medium {
  max-width: 1200px
}

.content-width-full {
  max-width: 100%
}

.ak-builder {

  /*@layer base {

  }*/

}

/*@apply overflow-hidden h-screen flex w-full;
  #ak-builder-scroll{
    @apply overflow-auto overscroll-contain w-full;
  }*/

.ak-builder .ak-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: stretch;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.ak-builder .form-input {
    /*@apply focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;*/
    /*@apply focus:border-none focus:ring-0;*/
  }

.ak-builder .ak-section-container {
  position: relative;
  margin-bottom: 2.5rem;
  width: 100%;
  border-color: transparent;
    border-width: 2px !important
}

.ak-builder .ak-section-container .ak-page-element-folder-header {
  height: 1.25rem
}

.ak-builder .ak-section-container .ak-section {
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ak-builder .ak-section-container .ak-section .ak-section-header {
  display: flex;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem
}

.ak-builder .ak-section-container .ak-section .ak-section-header .ak-section-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity))
}

.ak-builder .ak-section-container .ak-section .ak-section-header .ak-section-info {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.ak-builder .ak-section-container .ak-section .ak-section-action {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem
}

.ak-builder .ak-section-container .ak-section .ak-section-action .ak-section-action-right {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem
}

.ak-builder .ak-section-container .ak-section .ak-section-content {

      }

.ak-builder .ak-section-container .ak-section-element-container {
  border-radius: 0.5rem;
  padding-bottom: 1.5rem
}

.ak-builder .ak-section-container .ak-section-element-container .empty-folder {
  width: 100%;
  text-align: center
}

.ak-builder .ak-section-container .ak-action-search {
  display: flex;
  width: 10rem;
  cursor: pointer;
  align-items: stretch;
  border-radius: 0.25rem;
  --tw-ring-offset-width: 2px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-section-container .ak-action-search:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-section-container .ak-action-search.selected {
  border-radius: 0.25rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px
}

.ak-builder .ak-section-container .ak-action-search input {
  width: 100%;
  cursor: pointer;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-width: 1px;
  border-right-width: 0px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  padding-left: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity))
}

.ak-builder .ak-section-container .ak-action-search input:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.ak-builder .ak-section-container .ak-action-search input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px;
  outline-width: 0px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-section-container .ak-action-search input:active {
  border-width: 1px;
  border-right-width: 0px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.ak-builder .ak-section-container .ak-action-search button {
  width: 2rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-width: 1px;
  border-left-width: 0px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  padding-right: 0.5rem;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.ak-builder .ak-primary-button {
  display: inline-flex;
  -webkit-user-select: none;
          user-select: none;
  align-items: center;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-primary-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity))
}

.ak-builder .ak-primary-button:focus {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ak-builder .ak-primary-button i {
  padding-right: 0.25rem
}

.ak-builder span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.7);
  }

@keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }

.ak-builder .ak-sidebar-container {
  min-height: 100vh;
  width: 270px;
  min-width: 270px;
  max-width: 270px;
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-sidebar-container.hide-me {
  margin-left: -270px
}

.ak-builder .ak-sidebar-container .ak-sidebar {
  display: flex;
  flex-direction: column
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-logo {
  display: flex;
  height: 4rem;
  width: 100%;
  flex-grow: 1;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-logo-in {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity))
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu {
  width: 100%;
  padding-top: 1.5rem
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-item {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: flex-start;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-item .ak-menu-item-link {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-item .ak-menu-item-link .ak-menu-item-icon {
  display: flex;
  height: 1rem;
  width: 1rem;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1rem
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-item .ak-menu-item-link .ak-menu-item-icon div {
  display: flex;
  height: 1rem;
  width: 1rem;
  align-items: center
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-item .ak-menu-item-link .ak-menu-item-icon div svg {
  height: 100%;
  width: 100%;
  min-width: min-content;
  flex-shrink: 0;
  fill: currentColor;
  line-height: 1rem
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-item .ak-menu-item-link .ak-menu-item-title {
  margin-left: 1rem;
  flex-grow: 1;
  overflow-wrap: break-word
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-item.active {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-item.active .ak-menu-item-icon {
  --tw-scale-x: 115%;
  --tw-scale-y: 115%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-item:hover .ak-menu-item-icon {
  --tw-scale-x: 115%;
  --tw-scale-y: 115%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-dropdown {
  position: relative
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-dropdown .ak-menu-dropdown-icon {
  display: flex;
  height: 1rem;
  width: 1rem;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1rem;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-dropdown .ak-menu-dropdown-icon svg {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  fill: currentColor;
  line-height: 1rem
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-dropdown .ak-menu-dropdown-container {
  visibility: hidden;
  height: 0px;
  overflow: hidden;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-dropdown .ak-menu-dropdown-container .ak-menu-item-link {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 1.5rem
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-dropdown.active .ak-menu-dropdown-icon {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-dropdown.active .ak-menu-dropdown-container {
  visibility: visible
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-header-container {
  margin-bottom: 0.75rem;
  margin-top: 0.5rem;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
  padding-top: 0.75rem
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-header-container:first-child {
  margin-top: 0px;
  border-top-width: 0px;
  padding-top: 0px
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-header-container .ak-menu-header {
  margin-left: 1.5rem;
  margin-right: 0.75rem;
  margin-bottom: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-header-container .ak-menu-header .ak-menu-header-title {
  display: block;
  overflow-wrap: break-word;
  font-weight: 600;
  letter-spacing: 0.05em;
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity))
}

.ak-builder .ak-sidebar-container .ak-sidebar .ak-menu .ak-menu-header-container .ak-menu-header .ak-menu-header-sub-title {
  display: block;
  font-size: 11px;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

/*
@layer base {
}*/

.ak-builder .ak-content-container {
  position: relative;
  min-height: 100vh;
  width: 100%;
  min-width: 0px;
  flex: 1 1 0%;
  overflow: hidden;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-content-container .ak-header-container {
  position: relative;
  z-index: 50;
  display: flex;
  height: 3.5rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ak-builder .ak-content-container .ak-header-container .ak-header-left .ak-toggle-menu {
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  font-size: 22px;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-content-container .ak-header-container .ak-header-left .ak-toggle-menu:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-content-container .ak-header-container .ak-header-left .ak-toggle-menu > div {
  display: flex;
  height: 1.25rem;
  width: 1.25rem;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-content-container .ak-header-container .ak-header-left .ak-toggle-menu:hover > div {
  height: 1.5rem;
  width: 1.5rem
}

.ak-builder .ak-content-container .ak-header-container .ak-header-right {
  display: flex;
  align-items: center;
  gap: 0.5rem
}

.ak-builder .ak-content-container .ak-header-container .ak-header-right .sidebar-user {
  display: flex;
  align-items: center
}

.ak-builder .ak-content-container .ak-header-container .ak-header-right .sidebar-user .sidebar-user-image {
  order: 2
}

.ak-builder .ak-content-container .ak-header-container .ak-header-right .sidebar-user .sidebar-user-image img {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.375rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 1px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-content-container .ak-header-container .ak-header-right .sidebar-user .sidebar-user-image img:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity))
}

.ak-builder .ak-content-container .ak-header-container .ak-header-right .sidebar-user .sidebar-user-image img:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity))
}

.ak-builder .ak-content-container .ak-header-container .ak-header-right .sidebar-user .sidebar-user-info {
  order: 1;
  padding-right: 0.5rem;
  text-align: right
}

.ak-builder .ak-content-container .ak-header-container .ak-header-right .sidebar-user .sidebar-user-info .sidebar-user-name {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.ak-builder .ak-content-container .ak-header-container .ak-header-right .sidebar-user .sidebar-user-info .sidebar-user-email {
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.ak-builder .ak-content-container .ak-page-info-container {
  width: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem
}

.ak-builder .ak-content-container .ak-page-info-container .ak-breadcrums-container {
  -webkit-user-select: none;
          user-select: none;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem
}

.ak-builder .ak-content-container .ak-page-info-container .ak-breadcrums-container .ak-breadcrums-items {
  display: flex;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.ak-builder .ak-content-container .ak-page-info-container .ak-breadcrums-container .ak-breadcrums-items li {
  line-height: 1.25rem
}

.ak-builder .ak-content-container .ak-page-info-container .ak-breadcrums-container .ak-breadcrums-items li span {
  display: flex;
  height: 1rem;
  width: 1rem;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1rem
}

.ak-builder .ak-content-container .ak-page-info-container .ak-breadcrums-container .ak-breadcrums-items li span svg {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  fill: currentColor;
  line-height: 1rem
}

.ak-builder .ak-content-container .ak-page-info-container .ak-breadcrums-container .ak-breadcrums-items li a {
  line-height: 1.25rem
}

.ak-builder .ak-content-container .ak-page-info-container .ak-breadcrums-container .ak-breadcrums-items li:not(:first-child) {

              /*a {
                @apply pl-1;
              }*/
            }

.ak-builder .ak-content-container .ak-page-info-container .ak-breadcrums-container .ak-breadcrums-items li:not(:first-child):before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15'%3E%3Cpath d='M 7.293 14.707 a 1 1 0 0 1 0 -1.414 L 10.586 10 L 7.293 6.707 a 1 1 0 0 1 1.414 -1.414 l 4 4 a 1 1 0 0 1 0 1.414 l -4 4 a 1 1 0 0 1 -1.414 0 Z' fill='rgba(108, 117, 125)'/%3E%3C/svg%3E");
                float: left;
                padding-right: 0.25rem;
              }

.ak-builder .ak-content-container .ak-page-info-container .ak-page-title {
        /*@apply font-extrabold tracking-tight text-2xl text-gray-800;*/
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 600;
        line-height: 1;
        --tw-text-opacity: 1;
        color: rgb(17 24 39 / var(--tw-text-opacity));
      }

.ak-builder .ak-content-container .ak-page-info-container .ak-page-title h1 {
  line-height: 1.25
}

.ak-builder .ak-content-container .ak-page-info-container .ak-page-info {
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.ak-builder .ak-content-container .ak-page-info-container .ak-page-info:empty {
  display: none
}

.ak-builder .ak-content-container .ak-page-info-container .ak-page-info p {
  margin-top: 0.25rem
}

.ak-builder .ak-content-container .ak-page-info-container .ak-page-info p:empty {
  display: none
}

.ak-builder .ak-content-container .ak-page-info-container .ak-back-link {
  min-height: 30px;
  -webkit-user-select: none;
          user-select: none;
  padding-top: 0.25rem;
  line-height: 1.5rem
}

.ak-builder .ak-content-container .ak-page-info-container .ak-back-link a {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-content-container .ak-page-info-container .ak-back-link a:hover {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity))
}

.ak-builder .ak-content-container .ak-content {
  width: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 1rem
}

/*.ak-section-header{
        .ak-section-header-left{
          @apply flex-grow flex justify-center flex-col;
          .ak-section-title {
            @apply text-ellipsis;
          }
          .ak-section-info{
            @apply text-ellipsis;
          }
        }
        .ak-section-header-right{
          @apply flex-grow max-w-[280px] min-w-[280px] flex justify-end;
          .ak-section-action-left{
            @apply pr-2.5 flex items-center;
          }
          .ak-section-action-right{
            @apply flex items-center;
          }
        }
      }*/

.ak-builder .ak-table-container .ak-table .ak-table-content {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  overflow: hidden;
  overflow-x: auto;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.ak-builder .ak-table-container .ak-table .ak-table-content.selected {
  border-radius: 0.25rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px
}

.ak-builder .ak-table-container .ak-table .ak-table-content:hover {
  border-radius: 0.25rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px
}

.ak-builder .ak-table-container .ak-table .ak-table-content table {
  width: 100%;
  table-layout: auto;
  cursor: pointer

          /*.table-image {
            @apply max-w-[80px] max-h-[80px] rounded-lg inline;
          }*/
}

.ak-builder .ak-table-container .ak-table .ak-table-content table thead {
  border-bottom-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.ak-builder .ak-table-container .ak-table .ak-table-content table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.ak-builder .ak-table-container .ak-table .ak-table-content table tbody tr {
/* hover:bg-gray-100*/

            }

.ak-builder .ak-table-container .ak-table .ak-table-content table tbody tr:nth-child(even) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.ak-builder .ak-table-container .ak-table .ak-table-content table tbody tr {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.ak-builder .ak-table-container .ak-table .ak-table-content table tbody tr:last-child {
  border-style: none
}

.ak-builder .ak-table-container .ak-table .ak-table-content table tbody td {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity))
}

.ak-builder .ak-table-container .ak-table .ak-table-content table tbody td.nowrap {
  white-space: nowrap
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .table-icon-end {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.125rem
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .ak-multi-badge {
  margin-right: 0.125rem;
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .ak-text-center {
  text-align: center
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .table-icon-header {
  width: 100px;
  white-space: nowrap;
  text-align: center
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .table-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 12px;
  font-size: 0.875rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .table-icon:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .table-icon a{
              /*w-4 */
              display: flex;
              height: 100%;
              align-items: center;
            }

.ak-builder .ak-table-container .ak-table .ak-table-content table .table-icon div {
  display: flex;
  height: 100%;
  height: 1rem;
  width: 1rem;
  align-items: center
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .table-icon div svg {
                /*hover:text-blue-500 hover:scale-110 */
                height: 100%;
                width: 100%;
                min-width: min-content;
                flex-shrink: 0;
                cursor: pointer;
                fill: currentColor;
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 150ms;
              }

.ak-builder .ak-table-container .ak-table .ak-table-content table .table-icon span {
  padding-left: 0.25rem
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .delete-btn-th {
  display: flex;
  justify-content: flex-end
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .delete-btn:hover .table-icon div svg {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity))
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .delete-btn .form-checkbox {
              /*hover:border-gray-600 focus:border-gray-600 focus:ring-0 hover:scale-110 */
              margin-bottom: 2px;
              cursor: pointer;
              border-radius: 0.375rem;
              border-width: 1px;
              --tw-border-opacity: 1;
              border-color: rgb(156 163 175 / var(--tw-border-opacity));
              --tw-ring-offset-width: 0px;
              transition-property: all;
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-duration: 150ms;
            }

.ak-builder .ak-table-container .ak-table .ak-table-content table .delete-btn .form-checkbox:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .delete-btn .form-checkbox:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .delete-btn .form-checkbox:active {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

/*.delete-btn {
            //@apply p-0 w-12 text-center text-clip;
            !*a {
              @apply text-base text-gray-600 hover:text-red-600 hover:scale-110 block focus:text-red-700 transition-colors;
            }*!

          }*/

.ak-builder .ak-table-container .ak-table .ak-table-content table .table-sort {
  width: 2.5rem;
  text-align: center
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .table-id {
  width: 2.5rem;
  text-align: center
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .child-td {
  padding: 0px
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .child-td >div {
  display: flex;
  align-items: center;
  justify-content: center
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .edit-btn {
  width: 2.5rem;
  text-overflow: clip;
  padding: 0px;
  text-align: center
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .edit-btn a {
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .edit-btn a:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity))
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .edit-btn a:focus {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity))
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .ak-image-td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .table-image-link {
  max-height: 80px;
  max-width: 80px
}

.ak-builder .ak-table-container .ak-table .ak-table-content table .table-image-link.table-image {
  display: block;
  height: 4rem;
  width: 4rem;
  border-radius: 0.5rem
}

.ak-builder .ak-table-container.selected {
  border-radius: 0.5rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px
}

.ak-builder .ak-table-bottom {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.ak-builder .ak-table-bottom .ak-table-per-page {
  display: flex;
  gap: 0.5rem
}

.ak-builder .ak-table-bottom .ak-table-per-page .form-select {
  border-radius: 0.5rem;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-table-bottom .ak-table-per-page .form-select:focus {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-table-bottom .ak-table-pagination {
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  align-content: space-around;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.25rem;
  --tw-ring-offset-width: 2px
}

.ak-builder .ak-table-bottom .ak-table-pagination:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-table-bottom .ak-table-pagination.selected {
  border-radius: 0.25rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px
}

.ak-builder .ak-table-bottom .ak-table-pagination .ak-table-pagination-info {
  margin-left: 0.5rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.ak-builder .ak-table-bottom .ak-table-pagination .ak-table-pagination-nav {
  margin-left: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between
}

.ak-builder .ak-table-bottom .ak-table-pagination .ak-table-pagination-nav div {
  margin-right: -1px;
  cursor: pointer;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.ak-builder .ak-table-bottom .ak-table-pagination .ak-table-pagination-nav div:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-table-bottom .ak-table-pagination .ak-table-pagination-nav div:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}

.ak-builder .ak-table-bottom .ak-table-pagination .ak-table-pagination-nav div:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem
}

.ak-builder .ak-table-bottom .ak-table-pagination .ak-table-pagination-nav div.selected {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-multi-upload {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
  margin-top: 1rem
}

.ak-builder .ak-multi-upload label {
  display: block;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-wrap: wrap
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item {
  position: relative;
  margin: 0.5rem;
  display: flex;
  width: 150px;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-image-container {
  overflow: hidden
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-image-container .ak-gallery-image {
  height: 130px;
  width: 100%;
  background-size: cover;
  background-position: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-info-container {
  padding: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-info-container .ak-gallery-item-info {
  width: 100%;
  padding-bottom: 0.5rem
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-info-container .table-image-link.table-image {
  margin-bottom: 0.25rem;
  display: block;
  height: 4rem;
  width: 4rem;
  border-radius: 0.5rem
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.125rem;
  opacity: 0.2;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools:empty {
  display: none
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .edit-btn {
  height: 2rem;
  width: 2rem;
  text-overflow: clip;
  border-radius: 0.375rem;
  padding: 0.5rem;
  text-align: center
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .edit-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .edit-btn a {
  display: block;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .edit-btn a:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity))
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .edit-btn a:focus {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity))
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .show-btn {
  height: 2rem;
  width: 2rem;
  text-overflow: clip;
  border-radius: 0.375rem;
  padding: 0.5rem;
  text-align: center
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .show-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .sort-btn {
  height: 2rem;
  width: 2rem;
  text-overflow: clip;
  border-radius: 0.375rem;
  padding: 0.5rem;
  text-align: center
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .sort-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .delete-btn {
  height: 2rem;
  width: 2rem;
  text-overflow: clip;
  border-radius: 0.375rem;
  padding: 0.5rem;
  text-align: center
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .delete-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .delete-btn {

              /*@apply text-center text-clip;
              a {
                @apply px-1.5 text-base text-gray-600 hover:text-red-600 hover:scale-110 block focus:text-red-700 transition-colors;
              }*/
            }

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .delete-btn .table-icon div svg:hover {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity))
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .delete-btn .form-checkbox {
  margin-bottom: 2px;
  cursor: pointer;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  vertical-align: top;
  --tw-ring-offset-width: 0px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .delete-btn .form-checkbox:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .delete-btn .form-checkbox:focus {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .ak-gallery-tools .delete-btn .form-checkbox:active {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .table-icon {
  display: flex;
  height: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .table-icon div {
  display: flex;
  height: 100%;
  width: 1rem;
  align-items: center
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .table-icon div svg {
                /*hover:text-blue-500 hover:scale-110 */
                height: 100%;
                width: 100%;
                min-width: min-content;
                flex-shrink: 0;
                cursor: pointer;
                fill: currentColor;
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 150ms;
              }

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .table-icon span {
  padding-left: 0.5rem
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .gallery-child-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25rem;
  padding-top: 0.5rem
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .child-page {
  border-radius: 0.5rem;
  padding: 0.5rem
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .child-page:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .child-page .table-icon {
  display: flex;
  height: 1rem;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .child-page .table-icon a{
                /*w-4 */
                display: flex;
                height: 100%;
                align-items: center;
              }

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .child-page .table-icon div {
  display: flex;
  height: 100%;
  width: 1rem;
  align-items: center
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .child-page .table-icon div svg {
                  /*hover:text-blue-500 hover:scale-110 */
                  height: 100%;
                  width: 100%;
                  min-width: min-content;
                  flex-shrink: 0;
                  cursor: pointer;
                  fill: currentColor;
                  transition-property: all;
                  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                  transition-duration: 150ms;
                }

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item .child-page .table-icon span {
  padding-left: 0.25rem
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item:hover {
  background-color: rgb(243 244 246 / 0.2);
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item:hover .ak-gallery-image {
  --tw-scale-x: 103%;
  --tw-scale-y: 103%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item:hover .ak-gallery-tools {
  opacity: 0.9
}

.ak-builder .ak-gallery-container .ak-gallery .ak-gallery-content .ak-gallery-item:hover .ak-gallery-tools a {

              }

.ak-builder .ak-gallery-container.selected {
  border-radius: 0.5rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity))
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  display: flex;
  flex-wrap: wrap
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item {
  position: relative;
  margin: 0.5rem;
  display: flex;
  width: 18rem;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-image-container {
  overflow: hidden
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-image-container .ak-cards-image {
  height: 13rem;
  width: 100%;
  background-size: cover;
  background-position: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-info-container {
  padding: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-info-container .ak-cards-info {
  width: 100%
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-info-container .ak-cards-info:first-child {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-tools {
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  width: auto;
  align-items: center;
  justify-content: flex-end;
  border-bottom-left-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.25rem;
  opacity: 0.5;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-tools:hover {
  opacity: 0.9
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-tools .edit-btn {
  text-overflow: clip;
  text-align: center
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-tools .edit-btn a {
  display: block;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-tools .edit-btn a:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity))
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-tools .edit-btn a:focus {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity))
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-tools .delete-btn {
  text-overflow: clip;
  text-align: center
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-tools .delete-btn a {
  display: block;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-tools .delete-btn a:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity))
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item .ak-cards-tools .delete-btn a:focus {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity))
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item:hover {
  background-color: rgb(243 244 246 / 0.2);
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item:hover .ak-cards-image {
  --tw-scale-x: 103%;
  --tw-scale-y: 103%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item:hover .ak-cards-tools {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  opacity: 0.9
}

.ak-builder .ak-cards-container .ak-cards .ak-cards-content .ak-cards-item:hover .ak-cards-tools a {

              }

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  display: flex;
  flex-wrap: wrap
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item {
  position: relative;
  margin: 0.5rem;
  display: flex;
  min-height: 200px;
  width: 470px;
  min-width: 0px;
  flex-direction: row;
  overflow: hidden;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-image-container {
  width: 13rem;
  min-width: min-content;
  overflow: hidden
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-image-container .ak-cards-hrz-image {
  height: 100%;
  width: 13rem;
  background-size: cover;
  background-position: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-info-container {
  height: 100%;
  width: auto;
  min-width: 0px;
  max-width: max-content;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-info-container .ak-cards-hrz-info:first-child {
  padding-bottom: 0.25rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
  line-height: 1
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-info-container .table-image-link.table-image {
  margin-bottom: 0.25rem;
  display: block;
  height: 4rem;
  width: 4rem;
  border-radius: 0.5rem
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  height: 2.5rem;
  width: 210px;
  align-items: center;
  justify-content: space-between;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.125rem;
  opacity: 0.2;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .edit-btn {
  height: 2rem;
  width: 2rem;
  text-overflow: clip;
  border-radius: 0.375rem;
  padding: 0.5rem;
  text-align: center
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .edit-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .edit-btn a {
  display: block;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .edit-btn a:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity))
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .edit-btn a:focus {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity))
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .show-btn {
  height: 2rem;
  width: 2rem;
  text-overflow: clip;
  border-radius: 0.375rem;
  padding: 0.5rem;
  text-align: center
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .show-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .sort-btn {
  height: 2rem;
  width: 2rem;
  text-overflow: clip;
  border-radius: 0.375rem;
  padding: 0.5rem;
  text-align: center
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .sort-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .delete-btn {
  height: 2rem;
  width: 2rem;
  text-overflow: clip;
  border-radius: 0.375rem;
  padding: 0.5rem;
  text-align: center
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .delete-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .delete-btn {
              /*@apply text-center text-clip;
              a {
                @apply px-1.5 text-base text-gray-600 hover:text-red-600 hover:scale-110 block focus:text-red-700 transition-colors;
              }*/
            }

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .delete-btn .table-icon div svg:hover {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity))
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .delete-btn .form-checkbox {
  margin-bottom: 2px;
  cursor: pointer;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  vertical-align: top;
  --tw-ring-offset-width: 0px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .delete-btn .form-checkbox:hover {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .delete-btn .form-checkbox:focus {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .ak-cards-hrz-tools .delete-btn .form-checkbox:active {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .table-icon {
  display: flex;
  height: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .table-icon div {
  display: flex;
  height: 100%;
  width: 1rem;
  align-items: center
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .table-icon div svg {
                /*hover:text-blue-500 hover:scale-110 */
                height: 100%;
                width: 100%;
                min-width: min-content;
                flex-shrink: 0;
                cursor: pointer;
                fill: currentColor;
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 150ms;
              }

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .table-icon span {
  padding-left: 0.5rem
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .cards-hrz-child-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25rem;
  padding-top: 0.5rem
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .child-page {
  border-radius: 0.5rem;
  padding: 0.5rem
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .child-page:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .child-page .table-icon {
  display: flex;
  height: 1rem;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .child-page .table-icon a{
                /*w-4 */
                display: flex;
                height: 100%;
                align-items: center;
              }

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .child-page .table-icon div {
  display: flex;
  height: 100%;
  width: 1rem;
  align-items: center
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .child-page .table-icon div svg {
                  /*hover:text-blue-500 hover:scale-110 */
                  height: 100%;
                  width: 100%;
                  min-width: min-content;
                  flex-shrink: 0;
                  cursor: pointer;
                  fill: currentColor;
                  transition-property: all;
                  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                  transition-duration: 150ms;
                }

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item .child-page .table-icon span {
  padding-left: 0.25rem
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item:hover {
  background-color: rgb(243 244 246 / 0.2);
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item:hover .ak-cards-hrz-image {
  --tw-scale-x: 103%;
  --tw-scale-y: 103%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item:hover .ak-cards-hrz-tools {
  opacity: 0.9
}

.ak-builder .ak-cards-hrz-container .ak-cards-hrz .ak-cards-hrz-content .ak-cards-hrz-item:hover .ak-cards-hrz-tools a {

              }

.ak-builder .ak-cards-hrz-container.selected {
  border-radius: 0.5rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity))
}

.ak-builder .ak-form-container {
  position: relative;
  margin-bottom: 2.5rem;
  width: 100%

    /*END FORM STYLE*/
}

.ak-builder .ak-form-container .ak-section {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 1.5rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

@media (min-width: 640px) {

  .ak-builder .ak-form-container .ak-section {
    overflow: hidden;
    border-radius: 0.375rem
  }
}

.ak-builder .ak-form-container .ak-section .ak-section-header {
  display: flex;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem
}

.ak-builder .ak-form-container .ak-section .ak-section-header .ak-section-title {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity))
}

.ak-builder .ak-form-container .ak-section .ak-section-header .ak-section-info {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.ak-builder .ak-form-container .ak-section .ak-section-action {
  display: flex;
  justify-content: space-between;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-bottom: 1.5rem
}

/*@apply pb-4;*/

.ak-builder .ak-form-container .ak-section .ak-section-content .ak-section-form-content {
  margin-left: 0.75rem;
  margin-right: 0.75rem
}

/*FORM BUTTONS ON THE BOTTOM*/

.ak-builder .ak-form-container .ak-section .ak-section-content .ak-section-form-buttons {
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

@media (min-width: 640px) {

  .ak-builder .ak-form-container .ak-section .ak-section-content .ak-section-form-buttons {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }
}

/*@apply border-t border-gray-200 bg-gray-100 pl-5 pr-6 py-4 mt-4 rounded-b-2xl;*/

.ak-builder .ak-form-container .ak-section .ak-section-content .ak-section-form-buttons .ak-form-button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: stretch
}

.ak-builder .ak-form-container .ak-section .ak-section-content .ak-section-form-buttons .ak-form-label {
  width: 100%;
  min-width: 200px;
  max-width: 200px;
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  line-height: 18px
}

.ak-builder .ak-form-container .ak-section .ak-section-content .ak-section-form-buttons .ak-form-input {
  display: flex;
  width: 100%;
  justify-content: space-between
}

.ak-builder .ak-form-container.ak-form-manager .ak-form-info {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.ak-builder .ak-form-container.ak-form-manager .ak-section-content .ak-section-form-content {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem
}

.ak-builder .ak-form-container.ak-form-manager .ak-section-content .ak-section-form-content .ak-form-group {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-form-container.ak-form-manager .ak-section-content .ak-section-form-content label span {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

/*FORM STYLE*/

.ak-builder .ak-form-container .ak-form-group {
      /*flex flex-row flex-nowrap items-stretch content-start justify-start */
      /*hover:bg-gray-100*/
      margin: 3px;
      border-radius: 0.375rem;
    }

.ak-builder .ak-form-container .ak-form-group:hover {
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 0px
}

.ak-builder .ak-form-container .ak-form-group.ak-element-direction-vertical .label-checkbox {
  width: 100%
}

.ak-builder .ak-form-container .ak-form-group.ak-element-direction-vertical .label-checkbox span.checkbox-move {
  margin-right: 0.25rem;
  cursor: move
}

.ak-builder .ak-form-container .ak-form-group.ak-element-direction-horizontal {
        /*.label-checkbox{
          @apply w-full;
        }*/
      }

.ak-builder .ak-form-container .ak-form-group.ak-w-1_1 {
  width: 100%
}

.ak-builder .ak-form-container .ak-form-group.ak-w-2_3 {
        /*@apply w-1/2 -mx-[3px];*/
        width: calc(66.66% - 6px);
      }

.ak-builder .ak-form-container .ak-form-group.ak-w-3_4 {
        /*@apply w-1/2 -mx-[3px];*/
        width: calc(75% - 6px);
      }

.ak-builder .ak-form-container .ak-form-group.ak-w-1_2 {
        /*@apply w-1/2 -mx-[3px];*/
        width: calc(50% - 6px);
      }

.ak-builder .ak-form-container .ak-form-group.ak-w-1_3 {
        /*@apply w-1/3;*/
        width: calc(33.33% - 6px);
      }

.ak-builder .ak-form-container .ak-form-group.ak-w-1_4 {
        /*@apply w-1/4;*/
        width: calc(25% - 6px);
      }

.ak-builder .ak-form-container .ak-form-group.ak-w100 {
  width: 100%
}

.ak-builder .ak-form-container .ak-form-group.ak-w50 {
  width: 50%
}

.ak-builder .ak-form-container .ak-form-group .ak-form-label {
  margin-bottom: 0.25rem;
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
        /*@apply text-gray-800 w-full min-w-[200px] max-w-[200px] py-[11px] text-sm font-medium leading-[18px];*/
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input {
  width: 100%
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input .ak-form-prefix {
  display: flex
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input .ak-form-prefix .form-input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input .ak-form-prefix .form-select {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input .ak-form-suffix {
  display: flex
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input .ak-form-suffix .form-input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input .ak-form-suffix .form-select {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input-map {
  display: flex;
  width: 100%
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input-map .ak-form-input-map-display {
  width: 66.666667%;
  padding-right: 1rem
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input-map .ak-form-input-map-display img {
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input-map .ak-form-input-map-input {
  width: 33.333333%
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input-map .ak-form-info {
  padding-left: 0px
}

.ak-builder .ak-form-container .ak-form-group .ak-prefix {
  display: block;
  height: 40px;
  cursor: pointer;
  border-radius: 0.375rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-width: 1px;
  border-right-width: 0px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.625rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 18px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-form-container .ak-form-group .ak-prefix:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.ak-builder .ak-form-container .ak-form-group .ak-suffix {
  display: block;
  height: 40px;
  cursor: pointer;
  border-radius: 0.375rem;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-width: 1px;
  border-left-width: 0px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.625rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 18px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-form-container .ak-form-group .ak-suffix:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.ak-builder .ak-form-container .ak-form-group .ak-form-date-time {
  display: flex
}

.ak-builder .ak-form-container .ak-form-group .ak-form-date-time .form-input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px
}

.ak-builder .ak-form-container .ak-form-group .ak-form-date-time span {
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 0.375rem;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-width: 1px;
  border-left-width: 0px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  line-height: 18px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-form-container .ak-form-group .ak-form-date-time span:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.ak-builder .ak-form-container .ak-form-group .ak-form-info {
        /*@apply text-xs leading-3 text-gray-500 pt-1 px-2 font-medium;*/
        margin-top: 0.25rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity));
      }

.ak-builder .ak-form-container .ak-form-group .form-input, .ak-builder .ak-form-container .ak-form-group .form-select, .ak-builder .ak-form-container .ak-form-group .form-textarea, .ak-builder .ak-form-container .ak-form-group .form-input-multi-select {
        /*@apply transition-colors py-2.5 rounded-xl text-sm leading-[18px] w-full border-gray-300 border ring-0 focus:ring-0 hover:border-gray-400 focus:border-gray-400 placeholder:italic placeholder:text-gray-300;*/
        display: block;
        width: 100%;
        border-radius: 0.375rem;
        --tw-border-opacity: 1;
        border-color: rgb(209 213 219 / var(--tw-border-opacity));
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
      }

.ak-builder .ak-form-container .ak-form-group .form-input:focus, .ak-builder .ak-form-container .ak-form-group .form-select:focus, .ak-builder .ak-form-container .ak-form-group .form-textarea:focus, .ak-builder .ak-form-container .ak-form-group .form-input-multi-select:focus {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity))
}

@media (min-width: 640px) {

  .ak-builder .ak-form-container .ak-form-group .form-input, .ak-builder .ak-form-container .ak-form-group .form-select, .ak-builder .ak-form-container .ak-form-group .form-textarea, .ak-builder .ak-form-container .ak-form-group .form-input-multi-select {
    font-size: 0.875rem;
    line-height: 1.25rem
  }
}

.ak-builder .ak-form-container .ak-form-group .form-input-color {
  height: 2.25rem;
  max-width: 38px;
  cursor: pointer;
  overflow: hidden;
  padding: 0px
}

.ak-builder .ak-form-container .ak-form-group .form-input-color::-webkit-color-swatch-wrapper {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.25rem
}

.ak-builder .ak-form-container .ak-form-group .form-input-color::-webkit-color-swatch {
  border-radius: 0.375rem;
  border-color: transparent
}

.ak-builder .ak-form-container .ak-form-group .form-input-range {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  height: 0.5rem;
  width: 100%;
  cursor: pointer;
  appearance: none;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.ak-builder .ak-form-container .ak-form-group .form-input-multi-select {
  display: flex;
  min-height: 40px;
  flex-wrap: wrap;
  padding: 0.25rem
}

.ak-builder .ak-form-container .ak-form-group .form-input-multi-select div {
  margin-right: 0.25rem;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.ak-builder .ak-form-container .ak-form-group .form-textarea {
  height: 9rem;
  vertical-align: top
}

.ak-builder .ak-form-container .ak-form-group .form-file {
  display: block;
  width: 100%;
  cursor: pointer;
  appearance: none;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity))
}

.ak-builder .ak-form-container .ak-form-group .form-file::file-selector-button {
  margin-right: 1rem;
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity))
}

.ak-builder .ak-form-container .ak-form-group .form-file:hover {
  cursor: pointer
}

.ak-builder .ak-form-container .ak-form-group .form-file::file-selector-button:hover {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity))
}

.ak-builder .ak-form-container .ak-form-group .form-file:focus {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  outline: 2px solid transparent;
  outline-width: 0px;
  outline-offset: 0px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-form-container .ak-form-group .form-file::file-selector-button:active {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity))
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input-checkbox {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  padding-top: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 18px
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input-checkbox-toggle {
  padding-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 18px
}

.ak-builder .ak-form-container .ak-form-group .ak-form-input-checkbox-toggle label {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  align-items: center
}

.ak-builder .ak-form-container .ak-form-group .label-checkbox {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-bottom: 0.5rem;
  padding-right: 0.25rem
}

.ak-builder .ak-form-container .ak-form-group .label-checkbox span {
  overflow: hidden;
  white-space: nowrap;
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.ak-builder .ak-form-container .ak-form-group .form-checkbox {
  margin-top: 1px;
  margin-right: 0.25rem;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 18px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 0px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-form-container .ak-form-group .form-checkbox:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.ak-builder .ak-form-container .ak-form-group .form-checkbox:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-form-container .ak-form-group .form-radio {
  border-radius: 9999px;
  border-width: 1px;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 18px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 0px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-form-container .ak-form-group .form-radio:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.ak-builder .ak-form-container .ak-form-group .form-radio:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-builder .ak-form-container .ak-form-group .form-header {
  display: flex;
  min-height: 40px;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly
}

.ak-builder .ak-form-container .ak-form-group .form-header h3 {
  font-weight: 500;
  line-height: 1rem
}

.ak-builder .ak-form-container .ak-form-group .form-header .ak-form-info {
  padding-left: 0px;
  padding-top: 0px
}

.ak-builder .ak-form-container .from-button-submit, .ak-builder .ak-form-container .from-button-cancel {
      /*@apply max-w-[200px] mr-0 font-bold px-4 py-2.5 text-sm text-white transition-colors duration-150 bg-blue-700 rounded-2xl focus:shadow active:bg-gray-600 hover:bg-blue-800 disabled:bg-gray-500 transition-all w-full text-center;*/
      width: 100%;
      border-radius: 0.5rem;
      --tw-bg-opacity: 1;
      background-color: rgb(29 78 216 / var(--tw-bg-opacity));
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      text-align: center;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 500;
      --tw-text-opacity: 1;
      color: rgb(255 255 255 / var(--tw-text-opacity));
    }

.ak-builder .ak-form-container .from-button-submit:hover, .ak-builder .ak-form-container .from-button-cancel:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity))
}

.ak-builder .ak-form-container .from-button-submit:focus, .ak-builder .ak-form-container .from-button-cancel:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity))
}

:is(.dark .ak-builder .ak-form-container .from-button-submit),:is(.dark  .ak-builder .ak-form-container .from-button-cancel) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity))
}

:is(.dark .ak-builder .ak-form-container .from-button-submit:hover),:is(.dark  .ak-builder .ak-form-container .from-button-cancel:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity))
}

:is(.dark .ak-builder .ak-form-container .from-button-submit:focus),:is(.dark  .ak-builder .ak-form-container .from-button-cancel:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity))
}

@media (min-width: 640px) {

  .ak-builder .ak-form-container .from-button-submit, .ak-builder .ak-form-container .from-button-cancel {
    width: auto
  }
}

.ak-builder .ak-form-container .from-button-cancel {
  max-width: 150px;
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-form-container .from-button-cancel:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-form-container .from-button-cancel:active {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.ak-builder .ak-table-page-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem
}

.ak-builder .ak-table-page-container.pb0 {
  padding-bottom: 0px
}

.ak-builder .ak-table-page-container.pb5 {
  padding-bottom: 1.25rem
}

.ak-builder .ak-widget-container .ak-widget-date-picker {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 0
}

.ak-builder .ak-widget-container .ak-widget-date-picker .icon svg {
  z-index: 0;
  fill: currentColor;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-builder .ak-widget-container .ak-widget-date-picker .icon svg:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

.ak-builder .ak-widget-container .ak-section-element-container {
  padding: 0px
}

.ak-builder .ak-widget-container.ak-widget-container-custom .ak-section-element-container {
  display: flex;
  min-height: 80px;
  align-items: center;
  overflow: hidden;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem
}

.ak-builder .ak-widget-container.ak-widget-container-custom .ak-section-element-container div {
  max-width: 100%;
  overflow-wrap: break-word
}

.ak-builder .ak-widget-container .ak-widget-small {
  display: flex;
  height: 10rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0.75rem;
  padding-bottom: 0px;
  text-align: center;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ak-builder .ak-widget-container .ak-widget-small h3 {
  font-size: 1rem;
  line-height: 1.5rem
}

.ak-builder .ak-widget-container .ak-widget-small .widget-numbers {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600
}

.ak-builder .ak-widget-container .ak-widget-tall {
  height: 360px
}

.ak-builder .ak-widget-container.widget-width-ak-w-1_1 {
  flex-basis: 100%
}

.ak-builder .ak-widget-container.widget-width-ak-w-1_2 {
  width: 50%;
  max-width: calc(50% - 0.25rem - 4px)
}

.ak-builder .ak-widget-container.widget-width-ak-w-1_3 {
  width: 33.333333%;
  max-width: calc(33% - 0.25rem - 3px)
}

.ak-builder .ak-widget-container.widget-width-ak-w-1_4 {
  width: 25%;
  max-width: calc(25% - 0.25rem - 8px)
}

.ak-builder .ak-widget-container.selected {
  border-radius: 0.5rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px
}

.ak-builder .ak-widget-container.widget-error {
  border-radius: 0.5rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px
}

/*admiko toolbox*/

.admiko-tools-container {
  position: relative;
  min-height: 100%;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

/*@apply transition-all bg-gray-900 text-gray-400 w-[400px] min-w-[400px] max-w-[400px] h-screen sticky top-0;*/

/*height: calc(100vw - (100vw - 100%));*/

.admiko-tools-container .admiko-tools-container-toggle {
  position: fixed;
  top: 0.5rem;
  right: -400px;
  z-index: 50;
  display: flex;
  height: 2rem;
  width: 2rem;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-width: 1px;
  border-right-width: 0px;
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .admiko-tools-container-toggle:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  cursor: pointer;
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity))
}

.admiko-tools-container.admiko-hide-container {
  margin-right: -400px
}

.admiko-tools-container.admiko-hide-container .admiko-tools-container-toggle {
  right: 0px
}

.admiko-tools-container .admiko-tools {
  position: sticky;
  top: 0px;
  display: flex;
  height: 100vh;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.admiko-tools-container .admiko-tools .admiko-header {
  display: flex;
  height: 3.5rem;
  -webkit-user-select: none;
          user-select: none;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  padding: 0.5rem
}

.admiko-tools-container .admiko-tools .admiko-header .admiko-header-title {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-transform: uppercase
}

.admiko-tools-container .admiko-tools .admiko-toggle-tools {
  display: flex;
  height: 2rem;
  width: 2.25rem;
  align-items: center;
  justify-content: center
}

.admiko-tools-container .admiko-tools .admiko-toggle-tools:hover {
  cursor: pointer
}

.admiko-tools-container .admiko-tools .admiko-toggle-tools .admiko-toggle-box {
  display: flex;
  height: 2.25rem;
  width: 2.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .admiko-tools .admiko-toggle-tools .admiko-toggle-box:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity))
}

.admiko-tools-container .admiko-tools .admiko-toggle-tools .admiko-toggle-box > div {
  display: flex;
  height: 1.25rem;
  width: 1.25rem;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .admiko-tools .admiko-toggle-tools .admiko-toggle-box > div svg {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  fill: currentColor;
  line-height: 1rem
}

.admiko-tools-container .admiko-tools .admiko-toggle-tools .admiko-toggle-box:hover > div {
  height: 1.5rem;
  width: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.admiko-tools-container .admiko-tools .info {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container {
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  overflow: hidden;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action {
  position: absolute;
  top: 0px;
  right: -100%;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

/*  */

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action.admiko-show {
  right: 0px
}

/*
      .admiko-page-menu-editor {
        .admiko-action-header {
          @apply border-r-2 border-r-blue-400;
        }
      }

      .admiko-section-editor {
        .admiko-action-header {
          @apply border-r-2 border-r-green-500;
        }
      }

      .admiko-element-editor {
        .admiko-action-header {
          @apply border-r-2 border-r-yellow-600;
        }
      }

      .admiko-form-editor {
        .admiko-action-header {
          @apply border-r-2 border-r-purple-700;
        }
      }

      .admiko-table-editor {
        .admiko-action-header {
          @apply border-r-2 border-r-lime-400;
        }
      }*/

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-header {
  display: flex;
  -webkit-user-select: none;
          user-select: none;
  align-items: center;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #111827 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #111827 var(--tw-gradient-via-position), var(--tw-gradient-to);
  --tw-gradient-to: #1f2937 var(--tw-gradient-to-position);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1rem;
  line-height: 1rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-header:hover {
  --tw-gradient-from: rgb(31 41 55 / 0.5) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to);
  --tw-gradient-to: rgb(55 65 81 / 0.5) var(--tw-gradient-to-position)
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-header.admiko-action-header-info:hover {
  --tw-gradient-from: #111827 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #111827 var(--tw-gradient-via-position), var(--tw-gradient-to);
  --tw-gradient-to: #1f2937 var(--tw-gradient-to-position)
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-header .info {
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-header .info i {
  margin-right: 0.75rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-header .info p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-header .info-header {
  width: 262px;
  flex-grow: 0
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-header.reverse {
  flex-direction: row-reverse;
  padding-left: 1rem;
  padding-right: 1rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-header.reverse .info {
  padding-left: 0.75rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-header.reverse .info i {
  margin-right: 0.75rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-header:hover .admiko-toggle-box {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-header:hover .admiko-toggle-box > div {
  height: 1.5rem;
  width: 1.5rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu {
  z-index: 20;
  height: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-tabs {
  display: flex;
  -webkit-user-select: none;
          user-select: none;
  align-items: stretch;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-tabs div {
  flex: 1 1 0%;
  cursor: pointer;
  padding: 1rem;
  font-weight: 500
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-tabs div:hover {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-tabs div span {
  padding-right: 0.5rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-tabs div.active {
  border-color: transparent;
  background-color: rgb(255 255 255 / 12%);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-ak-grow {
  height: 100%;
  flex-grow: 1
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body {
  background-color: rgb(255 255 255 / 12%);
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.4);
  --tw-shadow-colored: inset 0 0 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body h3 {
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item {
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  -webkit-user-select: none;
          user-select: none;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.75rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 1px;
  --tw-ring-offset-color: #000
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item .ak-admiko-item-box {
  display: flex;
  flex-grow: 1;
  align-items: center
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item .ak-admiko-item-box .ak-admiko-menu-item-title {

                }

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item .ak-admiko-menu-action {
  display: flex;
  width: 5rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item .ak-admiko-menu-action div {
  width: 33.333333%;
  padding: 0.5rem;
  line-height: 1rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item .ak-admiko-menu-action .ak-admiko-menu-action-move {
  cursor: s-resize
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item .ak-admiko-menu-action .ak-admiko-menu-action-move:active {
  cursor: grabbing
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item .ak-admiko-menu-item-icon {
  margin-right: 0.75rem;
  display: flex;
  height: 1rem;
  width: 1rem;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1rem;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item .ak-admiko-menu-item-icon svg {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  fill: currentColor;
  line-height: 1rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item-folder {
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 0.75rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item-folder:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 1px;
  --tw-ring-offset-color: #000
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item-folder > .ak-admiko-menu-item {
  margin-bottom: 0px;
  border-style: none
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item-folder > .ak-admiko-menu-item:hover {
  --tw-bg-opacity: 0;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 0px
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item-folder .droppable-folder {
  width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.25rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .ak-admiko-menu-item-folder .empty-folder {
  margin-bottom: 0.5rem;
  border-radius: 0.75rem;
  border-width: 1px;
  border-style: dashed;
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  line-height: 1rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .drag-clone-element {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  opacity: 0.3
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body .ak-admiko-menu-sort .active {
  width: 100%;
  border-style: dashed;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #000
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-info label {
  display: block;
  padding-top: 0.25rem;
  font-weight: 600
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-info {
  max-width: 100%;
  overflow-wrap: break-word;
  font-size: 0.875rem;
  line-height: 1.25rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-info .infoCode {
  display: block;
  padding-bottom: 0.25rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-info .infoCode span {
  overflow: hidden;
  letter-spacing: -20px
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body-action {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body-action .ak-toggle-settings-box .ak-toggle-settings {
  display: flex;
  align-items: center;
  justify-content: center
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body-action .ak-toggle-settings-box .ak-toggle-settings:hover {
  cursor: pointer
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body-action .ak-toggle-settings-box .ak-toggle-settings div {
  margin: auto;
  display: flex;
  height: 2rem;
  width: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body-action .ak-toggle-settings-box .ak-toggle-settings:hover div {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body-action .ak-toggle-settings-box .ak-toggle-settings i {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body-action .ak-toggle-settings-box .ak-more-settings {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  margin-top: 0.25rem;
  height: 0px;
  overflow: hidden;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body-action .ak-toggle-settings-box .ak-more-settings >:first-child {
  padding-top: 0.5rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .admiko-action-menu .admiko-action-menu-body-action .ak-toggle-settings-box.active i {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-page-element-container {
  display: grid;
  -webkit-user-select: none;
          user-select: none;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.75rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-page-element-container .add-page-element {
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  padding: 1rem;
  text-align: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-page-element-container .add-page-element:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-page-element-container .add-page-element i {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-page-element-container .add-page-element h2 {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-form-element-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.25rem;
  padding: 0.25rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-form-element-container .add-form-element {
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  border-width: 1px;
  border-style: dashed;
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
  padding: 0.5rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-form-element-container .add-form-element:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-form-element-container .add-form-element h3 {
  margin: 0px;
  white-space: nowrap;
  padding: 0px;
  font-size: 0.875rem;
  line-height: 1.25rem
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-form-element-container .add-form-element p {
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-form-element-container .add-form-element .add-form-element-form form {
  display: flex;
  flex-direction: row
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-form-element-container .add-form-element .add-form-element-form form .form-group {
  padding: 0px
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-form-element-container .add-form-element .add-form-element-form form .form-input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-form-element-container .add-form-element .add-form-element-form form .form-input::placeholder {
  font-style: italic;
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity))
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-form-element-container .add-form-element .add-form-element-form form .form-input:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 0px
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-form-element-container .add-form-element .add-form-element-form form .form-input:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 0px
}

.admiko-tools-container .admiko-tools .admiko-action-container .add-form-element-container .add-form-element .add-form-element-form form .button-submit {
  margin: 0px;
  width: 4rem;
  -webkit-user-select: none;
          user-select: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

/*FORM STYLE*/

.admiko-tools-container .form-input, .admiko-tools-container .form-select {
  width: 100%;
  border-radius: 0.5rem;
  border-width: 0px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #111827;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .form-input::placeholder, .admiko-tools-container .form-select::placeholder {
  font-style: italic;
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity))
}

.admiko-tools-container .form-input:hover, .admiko-tools-container .form-select:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.admiko-tools-container .form-input:focus, .admiko-tools-container .form-select:focus {
  border-width: 0px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.admiko-tools-container .form-textarea {
  display: block;
  width: 100%;
  border-radius: 0.5rem;
  border-width: 0px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #111827;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .form-textarea::placeholder {
  font-style: italic;
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity))
}

.admiko-tools-container .form-textarea:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.admiko-tools-container .form-textarea:focus {
  border-width: 0px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.admiko-tools-container .form-checkbox {
  margin-top: 9px;
  margin-bottom: 2px;
  border-radius: 0.375rem;
  border-width: 0px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #111827;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .form-checkbox:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.admiko-tools-container .form-checkbox:focus {
  border-width: 0px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.admiko-tools-container .form-checkbox:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity))
}

.admiko-tools-container .form-checkbox:disabled:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 0px
}

.admiko-tools-container form .form-checkbox {
  margin-top: 9px
}

.admiko-tools-container .from-button {
  margin-top: 0.25rem;
  width: 100%;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .from-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity))
}

.admiko-tools-container .from-button:focus {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.admiko-tools-container .from-button:active {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity))
}

.admiko-tools-container .from-button:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity))
}

.admiko-tools-container .from-button-close {
  margin: auto;
  margin-top: 0.25rem;
  max-width: 100px;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .from-button-close:hover {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity))
}

.admiko-tools-container .from-button-close:focus {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.admiko-tools-container .ak-admiko-form-error {
  display: none;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity))
}

.admiko-tools-container .ak-admiko-form-input-error ~ .ak-admiko-form-error {
  display: block
}

.admiko-tools-container .layoutSaveBtn {
  width: 100%
}

.admiko-tools-container .layoutSaveBtn .form-group {
  width: 100%
}

.admiko-tools-container .button-submit {
  display: flex;
  -webkit-user-select: none;
          user-select: none;
  justify-content: center
}

.admiko-tools-container .button-submit .button-state {
  width: 0px
}

.admiko-tools-container .button-submit .button-state .button-state-progress {
  margin-left: -1.5rem;
  display: inline-block;
  display: none
}

@keyframes spin {

  to {
    transform: rotate(360deg)
  }
}

.admiko-tools-container .button-submit .button-state .button-state-progress i {
  animation: spin 1s linear infinite
}

.admiko-tools-container .button-submit .button-state .button-state-progress.show-me {
  display: inline-block
}

.admiko-tools-container .button-submit .button-state .button-state-saved {
  margin-left: -1.5rem;
  display: none;
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
  opacity: 1
}

.admiko-tools-container .button-submit .button-state .button-state-saved.show-me {
  display: inline-block
}

@keyframes fadeOut {

  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.admiko-tools-container .button-submit .button-state .button-state-saved.show-me {
  animation: fadeOut 3.5s ease-in-out
}

@keyframes bounce {

  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.admiko-tools-container .button-submit .button-state .button-state-saved i {
  animation: bounce 1s infinite
}

.admiko-tools-container .delete-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity))
}

.admiko-tools-container .delete-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity))
}

.admiko-tools-container .form-group {
  display: flex;
  align-items: stretch;
  padding-bottom: 0.75rem
}

.admiko-tools-container .form-group label {
  width: 40%;
  -webkit-user-select: none;
          user-select: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.admiko-tools-container .form-group .form-group-input {
  width: 60%;
  flex: 1 1 0%
}

.admiko-tools-container .form-group .form-group-input .form-group-input-radio-inline {
  display: flex;
  height: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  font-size: 0.75rem;
  line-height: 1rem
}

.admiko-tools-container .form-group .form-group-input .form-group-input-radio-inline div {
  height: 100%;
  width: 100%;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.admiko-tools-container .form-group .form-group-input .form-group-input-radio-inline div:hover {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity))
}

.admiko-tools-container .form-group .form-group-input .form-group-input-radio-inline .active {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity))
}

.admiko-tools-container .form-group .form-group-input .form-group-input-radio-inline .active:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity))
}

.admiko-tools-container .form-group .form-group-input .form-group-input-radio-inline input {
  display: none
}

.admiko-tools-container .form-group .form-group-input .form-group-input-radio-inline label {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  text-align: center
}

.admiko-tools-container .form-group .form-group-input .form-group-input-radio-inline label:hover {
  cursor: pointer
}

.admiko-tools-container .admikoToolBoxImageSettings {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.5rem
}

.admiko-tools-container .admikoToolBoxImageSettings .form-group {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.75rem
}

.admiko-tools-container .admikoToolBoxImageSettings .form-group label {
  width: 100%;
  -webkit-user-select: none;
          user-select: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.admiko-tools-container .admikoToolBoxImageSettings .form-group .form-group-input {
  width: 100%
}

.admiko-tools-container .admikoToolBoxImageThumb {
  margin-bottom: 0.5rem;
  border-bottom-width: 1px;
  border-style: dotted;
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity))
}

.admiko-tools-container .admikoToolBoxImageThumb .admikoToolBoxImageThumbHeader {
  display: flex;
  justify-content: space-between
}

.admiko-tools-container .admikoToolBoxImageThumb .admikoToolBoxImageThumbHeader h4 {
  padding-bottom: 0.25rem
}

.admiko-tools-container .admikoToolBoxImageThumb .admikoToolBoxImageThumbHeader div i {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  line-height: 2rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .admikoToolBoxImageThumb .admikoToolBoxImageThumbHeader div i:hover {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity))
}

.admiko-tools-container .admikoToolBoxImageThumb .admikoToolBoxImageThumbSettings {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem
}

.admiko-tools-container .admikoToolBoxImageThumb .admikoToolBoxImageThumbAction {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.5rem
}

.admiko-tools-container .admikoToolBoxImageThumb .form-group {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.75rem
}

.admiko-tools-container .admikoToolBoxImageThumb .form-group label {
  width: 100%;
  -webkit-user-select: none;
          user-select: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.admiko-tools-container .admikoToolBoxImageThumb .form-group .form-group-input {
  width: 100%
}

.admiko-tools-container .ak-admiko-form-info {
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity))
}

.admiko-tools-container .form-group-helper p {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity))
}

.admiko-tools-container .ak-source-label-group {
  position: relative;
  flex-wrap: wrap;
  padding-top: 0.125rem;
  padding-bottom: 0px
}

.admiko-tools-container .ak-source-label-group:hover {
  z-index: 40
}

.admiko-tools-container .ak-source-label-group > .form-group-input {
  margin-bottom: 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between
}

/* h-[34px]*/

.admiko-tools-container .ak-source-label-group > .form-group-input select {
  max-width: 282px
}

.admiko-tools-container .ak-source-label-group > .form-group-input-widget select {
  max-width: 358px
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings {
  display: flex;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 0.5rem;
  padding: 0.125rem;
  padding-left: 0.25rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))

      /*.delete-label-group{
       @apply transition-all px-2 py-1 hover:bg-gray-700 leading-6 rounded-lg;
      }*/
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section {
  position: relative
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section .move-up, .admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section .move-down {
  position: absolute;
  left: 3px;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.25rem;
  text-align: center;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section .move-up:hover, .admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section .move-down:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section .move-up {
  top: -3px
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section .move-down {
  bottom: -3px
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section:hover {
  z-index: 40
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section:hover .move-up, .admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section:hover .move-down {
  z-index: 50;
  opacity: 1
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section:hover .move-up {
  top: -16px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section:hover .move-down {
  bottom: -16px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .move-section .mouse-move {
  cursor: move;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .element-edit, .admiko-tools-container .ak-source-label-group .admiko-form-settings .tft-move, .admiko-tools-container .ak-source-label-group .admiko-form-settings .delete-label-group {
  width: 2rem;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-align: center;
  line-height: 1.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .element-edit:hover, .admiko-tools-container .ak-source-label-group .admiko-form-settings .tft-move:hover, .admiko-tools-container .ak-source-label-group .admiko-form-settings .delete-label-group:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity))
}

.admiko-tools-container .ak-source-label-group .admiko-form-settings .tft-move {
  padding: 0px
}

.admiko-tools-container .ak-source-label-group:hover {

      /*.admiko-form-settings{
        @apply opacity-100 transition-opacity;
      }*/
    }

/*.form-input, */

.admiko-tools-container .ak-source-label-group:hover .form-file, .admiko-tools-container .ak-source-label-group:hover .form-select, .admiko-tools-container .ak-source-label-group:hover .form-textarea, .admiko-tools-container .ak-source-label-group:hover .form-textarea {
  cursor: pointer
}

/*&.selected{
      @apply bg-gray-100 z-20;
      .admiko-form-settings{
        @apply opacity-100;
      }
    }*/

.admiko-tools-container .ak-source-label-group .sort-me .drag-clone-element {
  border-radius: 0.75rem;
  opacity: 0.3
}

.admiko-tools-container .ak-source-label-group .sort-me .active {
  width: 100%;
  border-radius: 0.75rem;
  border-style: dashed;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 0px
}

.admiko-tools-container .ak-source-label-group-edit {
  display: flex;
  height: 0px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .ak-source-label-group-edit.show {
  height: 3rem;
  padding-bottom: 0.25rem
}

.admiko-tools-container .ak-source-label-group-edit .ak-source-label-group-edit-input {
  display: flex;
  max-width: 282px;
  flex-direction: row;
  align-items: center
}

.admiko-tools-container .ak-source-label-group-edit .ak-source-label-group-edit-input label {
  padding-left: 1rem
}

.admiko-tools-container .ak-source-widget-group-edit {
  display: flex;
  height: 0px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .ak-source-widget-group-edit.show {
  height: 2.5rem;
  padding-bottom: 0.25rem
}

.admiko-tools-container .ak-source-widget-group-edit .ak-source-widget-group {
  display: flex;
  width: 100%;
  max-width: 270px;
  flex-direction: column;
  padding-top: 0.25rem
}

.admiko-tools-container .ak-source-widget-group-edit .ak-source-widget-group .ak-group-widget-settings-edit-input {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem
}

.admiko-tools-container .ak-layout-group {
  position: relative;
  flex-wrap: wrap;
  padding-bottom: 0px
}

.admiko-tools-container .ak-layout-group > .form-group-input {
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.25rem
}

.admiko-tools-container .ak-layout-group > .form-group-input label {
  display: flex;
  width: 100%;
  align-items: center
}

.admiko-tools-container .ak-layout-group > .form-group-input label span {
  max-width: 262px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 0.5rem
}

.admiko-tools-container .ak-layout-group > .form-group-input .merged-icon {
  display: flex;
  align-items: center;
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.admiko-tools-container .ak-layout-group > .form-group-input .merged-icon i {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.admiko-tools-container .ak-layout-group .ak-group-element-settings-edit {
  display: flex;
  height: 0px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .ak-layout-group .ak-group-element-settings-edit.show {
  height: 11rem;
  padding-bottom: 0.25rem
}

.admiko-tools-container .ak-layout-group .ak-group-element-settings-edit.show.show-1 {
  height: 8rem
}

.admiko-tools-container .ak-layout-group .ak-group-element-settings-edit.show.show-3 {
  height: 14rem
}

/*&.show-layout {
        @apply h-28 pb-1;

        &.show-layout-1 {
          @apply h-24;
        }

        &.show-layout-3 {
          @apply h-44;
        }
      }*/

.admiko-tools-container .ak-layout-group .ak-group-element-settings-edit .ak-group-element-settings-edit-table-layout {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between
}

.admiko-tools-container .ak-layout-group .ak-group-element-settings-edit .ak-group-element-settings-edit-table-layout .ak-group-element-settings-edit-table-layout-form {
  width: 100%;
  max-width: 282px;
  padding-left: 0.5rem
}

.admiko-tools-container .ak-layout-group .ak-group-element-settings-edit .ak-group-element-settings-edit-table-layout .ak-layout-more-settings {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between
}

.admiko-tools-container .ak-layout-group .ak-group-element-settings-edit .ak-group-element-settings-edit-input {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.25rem
}

.admiko-tools-container .ak-layout-group .ak-group-element-settings-edit .ak-group-element-settings-edit-input label {
  padding-left: 0px
}

.admiko-tools-container .ak-source-sort-label {
  margin-right: 0.25rem
}

.admiko-tools-container .ak-source-sort-order {
  margin-left: 0.25rem;
  width: 100px;
  min-width: 100px;
  max-width: 100px
}

.admiko-tools-container .ak-custom-values-input-value {
  margin-right: 0.25rem;
  width: 100%
}

.admiko-tools-container .ak-custom-values-input-title {
  margin-left: 0.25rem;
  width: 100%
}

.admiko-tools-container .add-more-btn {
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  width: 100%;
  max-width: 94px;
  border-radius: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .add-more-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity))
}

.admiko-tools-container .add-more-btn:focus {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.admiko-tools-container .add-more-btn:active {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity))
}

.admiko-tools-container .add-more-btn:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity))
}

.admiko-tools-container .add-more-btn-box {
  display: flex;
  min-height: 48px;
  align-items: center;
  justify-content: space-between
}

.admiko-tools-container .add-more-btn-box .add-more-btn {
  margin: 0px;
  width: 94px;
  border-radius: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-container .add-more-btn-box .add-more-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity))
}

.admiko-tools-container .add-more-btn-box .add-more-btn:focus {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.admiko-tools-container .add-more-btn-box .add-more-btn:active {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity))
}

.admiko-tools-container .add-more-btn-box .add-more-btn:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity))
}

.admiko-tools-container h4 {
  padding-top: 0.375rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem
}

.admiko-tools-container .ak-table-settings {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;
  padding-bottom: 0.5rem
}

.admiko-tools-container .ak-table-settings .form-group {
  cursor: pointer;
  padding-bottom: 0px
}

.admiko-tools-container .ak-table-settings .form-group label {
  flex-grow: 1;
  cursor: pointer
}

.admiko-tools-container .ak-table-settings .form-group .form-group-input {
  display: flex;
  width: auto;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
  padding-right: 0.5rem
}

.admiko-tools-container .ak-table-settings .form-group:nth-child(even) .form-group-input {
  padding-right: 0px
}

/*END ADMIKO TOOLBOX*/

/*END ADMIKO TOOLBOX*/

/*END ADMIKO TOOLBOX*/

/*END ADMIKO TOOLBOX*/

.admiko-tools-menu-container {
  position: fixed;
  left: 271px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 50;
  height: 100%;
  background-color: rgb(255 255 255 / 0.5);
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-menu-container.admiko-fade {
  opacity: 1
}

.admiko-tools-menu-container .admiko-tools-menu-container-box {
  height: 100%;
  max-height: 100%;
  -webkit-user-select: none;
          user-select: none;
  padding: 1rem
}

.admiko-tools-menu-container .admiko-tools-menu-container-box .admiko-tools-container {
  height: 100%;
  max-height: 100%;
  min-height: 0px;
  overflow: hidden;
  border-radius: 0.75rem
}

.admiko-tools-menu-container .admiko-tools-menu-container-box .admiko-tools-container .admiko-tools {
  height: 100%;
  max-height: 100%;
  min-height: 0px;
  overflow: hidden;
  border-radius: 0.75rem
}

.admiko-tools-menu-container .admiko-tools-menu-container-box .admiko-tools-container .admiko-tools .admiko-action-container .admiko-action {
  position: absolute;
  top: 0px;
  right: -100%;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tools-menu-container .admiko-tools-menu-container-box .admiko-tools-container .admiko-tools .admiko-action-container .admiko-action.admiko-show {
  right: 0px
}

.admiko-ak-loading {
  width: 100%;
  padding: 1rem;
  text-align: center
}

@keyframes spin {

  to {
    transform: rotate(360deg)
  }
}

.admiko-ak-loading i {
  animation: spin 1s linear infinite
}

.icon-sub-page-info {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem
}

.icon-sub-page-info span {
  margin-right: 0.5rem
}

.icons-list-container .icons-list-search {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 1rem
}

.icons-list-container .icons-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.icons-list-container .icons-list .icons {
  margin-right: 0.125rem;
  margin-bottom: 0.125rem;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.icons-list-container .icons-list .icons svg {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  fill: currentColor;
  line-height: 1rem
}

.icons-list-container .icons-list .icons:hover {
  cursor: pointer;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity))
}

.icons-list-container .icons-list .icons.selected {
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity))
}

.icons-list-container .pagination {
  -webkit-user-select: none;
          user-select: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.75rem
}

.icons-list-container .pagination ul {
  display: flex;
  flex-wrap: wrap
}

.icons-list-container .pagination ul li {
  margin-right: 0.125rem;
  margin-bottom: 0.125rem;
  display: flex;
  height: 2.25rem;
  width: 2.25rem;
  cursor: pointer;
  align-items: center;
  border-radius: 0.375rem;
  line-height: 1.25;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.icons-list-container .pagination ul li:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

.icons-list-container .pagination ul li.active {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

.icons-list-container .pagination ul li span {
  width: 100%;
  text-align: center
}

.mouse-pointer {
  cursor: pointer
}

.ak-admiko-pre-scroll {
  display: flex;
  flex-direction: column;
  overflow: hidden
}

.ak-admiko-add-scroll {
  overflow: auto;
  overscroll-behavior: contain
}

.ak-admiko-add-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }

.ak-admiko-add-scroll::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
  }

.ak-admiko-add-scroll::-webkit-scrollbar-thumb {
    background-color: #000000;
  }

/*end admiko tools*/

.sort-me .drag-clone-element {
  border-radius: 0.75rem;
  opacity: 0.3
}

.sort-me .ak-form-group {
  border-width: 2px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.sort-me .ak-form-group-box {
  height: 100%;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px
}

.sort-me .ak-source-label-group {
  border-width: 1px;
  border-style: solid;
  border-color: transparent
}

.sort-me .active {
  width: 100%;
  border-radius: 0.75rem;
  border-width: 1px;
  border-style: dashed;
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
  opacity: 0.7;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff
}

.admiko-tf-settings {
  position: absolute;
  left: 50%;
  top: -18px;
  z-index: 10;
  margin: auto;
  display: flex;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -webkit-user-select: none;
          user-select: none;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.125rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  padding: 0.125rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.admiko-tf-settings:hover {
  z-index: 1000
}

.admiko-tf-settings .move-section {
  position: relative
}

.admiko-tf-settings .move-section .move-up, .admiko-tf-settings .move-section .move-down {
  position: absolute;
  left: 3px;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.25rem;
  text-align: center;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tf-settings .move-section .move-up:hover, .admiko-tf-settings .move-section .move-down:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.admiko-tf-settings .move-section .move-up {
  top: -10px
}

.admiko-tf-settings .move-section .move-down {
  bottom: -10px
}

.admiko-tf-settings .move-section:hover {
  z-index: 40
}

.admiko-tf-settings .move-section:hover .move-up, .admiko-tf-settings .move-section:hover .move-down {
  z-index: 40;
  opacity: 1
}

.admiko-tf-settings .move-section:hover .move-up {
  top: -18px;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tf-settings .move-section:hover .move-down {
  bottom: -18px;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tf-settings .move-section .mouse-move {
  cursor: move;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem
}

.admiko-tf-settings .table-btn-edit, .admiko-tf-settings .from-btn-edit, .admiko-tf-settings .table-btn-move {
  border-radius: 0.375rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.admiko-tf-settings .table-btn-edit:hover, .admiko-tf-settings .from-btn-edit:hover, .admiko-tf-settings .table-btn-move:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity))
}

.admiko-tf-settings .table-btn-edit a, .admiko-tf-settings .from-btn-edit a, .admiko-tf-settings .table-btn-move a {
  display: block;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem
}

.admiko-tf-settings .table-btn-edit.selected,.admiko-tf-settings .from-btn-edit.selected {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity))
}

.admiko-tf-settings .table-btn-move {
  padding: 0px
}

.ak-form-group {
  position: relative;
  z-index: 40;
  cursor: pointer
}

.ak-form-group .admiko-form-settings {
  position: absolute;
  right: 6px;
  top: -4px;
  z-index: 40;
  margin: auto;
  display: flex;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  padding: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  opacity: 0
}

.ak-form-group .admiko-form-settings .move-section {
  position: relative
}

.ak-form-group .admiko-form-settings .move-section .move-up, .ak-form-group .admiko-form-settings .move-section .move-down {
  position: absolute;
  left: 3px;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.25rem;
  text-align: center;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-form-group .admiko-form-settings .move-section .move-up:hover, .ak-form-group .admiko-form-settings .move-section .move-down:hover {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.ak-form-group .admiko-form-settings .move-section .move-up {
  top: -3px
}

.ak-form-group .admiko-form-settings .move-section .move-down {
  bottom: -3px
}

.ak-form-group .admiko-form-settings .move-section:hover {
  z-index: 40
}

.ak-form-group .admiko-form-settings .move-section:hover .move-up, .ak-form-group .admiko-form-settings .move-section:hover .move-down {
  z-index: 50;
  opacity: 1
}

.ak-form-group .admiko-form-settings .move-section:hover .move-up {
  top: -18px;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-form-group .admiko-form-settings .move-section:hover .move-down {
  bottom: -18px;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-form-group .admiko-form-settings .move-section .mouse-move {
  cursor: move;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.ak-form-group .admiko-form-settings .element-edit, .ak-form-group .admiko-form-settings .tft-move {
      /*@apply transition-all px-2 py-1 leading-6 hover:bg-gray-700 rounded-lg w-8 text-center;*/
      border-radius: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
    }

.ak-form-group .admiko-form-settings .element-edit:hover, .ak-form-group .admiko-form-settings .tft-move:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity))
}

.ak-form-group .admiko-form-settings .tft-move {
  padding: 0px
}

/*.admiko-form-settings-width {
    @apply flex flex-row justify-between m-auto p-[0.1rem] absolute right-[6px] transform bottom-[-4px] bg-gray-900 text-white text-sm rounded-lg z-40 opacity-0;
    .form-group-input-radio-inline{
      @apply flex flex-nowrap justify-around items-center h-6 bg-gray-800 text-xs overflow-hidden;
      div{
        @apply text-white h-full hover:bg-gray-700 w-full hover:cursor-pointer text-center rounded-none border-0;
      }
      .active {
        @apply bg-gray-600 hover:bg-gray-600  rounded-none border-0;
      }
      input{
        @apply hidden;
      }
      label{
        @apply text-center p-0 px-1 hover:cursor-pointer w-full h-full flex items-center justify-center;
      }
    }
  }*/

.ak-form-group:hover .form-input, .ak-form-group:hover .form-file, .ak-form-group:hover .form-select, .ak-form-group:hover .form-textarea {
  cursor: pointer
}

.ak-form-group:hover .admiko-form-settings,.ak-form-group:hover .admiko-form-settings-width {
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

/* &.selected {
    @apply bg-gray-100 z-20;
    .admiko-form-settings {
      @apply opacity-100;
    }
  }*/

.ak-form-group.selected {
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 0px
}

.ak-form-group.selected .admiko-form-settings,.ak-form-group.selected .admiko-form-settings-width {
  opacity: 1
}

.ak-form-group.selected.active {
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ak-form-group.ak-form-error label {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity))
}

.ak-form-group .ak-text-area .ak-editor-toolbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.ak-form-group .ak-text-area .ak-editor-toolbox div {
  height: 30px;
  width: 30px;
  padding: 0.25rem;
  text-align: center
}

.ak-admiko-menu-edit, .ak-admiko-page-edit {
  position: absolute;
  left: 100%;
  top: 6px;
  z-index: 60;
  display: flex;
  display: none;
  height: 2rem;
  width: 2rem;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ak-admiko-menu-edit:hover, .ak-admiko-page-edit:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  cursor: pointer
}

@keyframes spin {

  to {
    transform: rotate(360deg)
  }
}

.ak-admiko-menu-edit:hover i, .ak-admiko-page-edit:hover i {
  animation: spin 8s linear infinite
}

.ak-menu-dropdown-container .ak-admiko-menu-edit {
  left: auto;
  right: 0px;
  top: 4px
}

.ak-admiko-page-edit {
  right: -0.5rem;
  left: auto;
  display: flex;
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity))
}

.ak-menu-header-container, .ak-admiko-menu-item-link {
  position: relative
}

.ak-menu-header-container .ak-admiko-menu-edit {
  top: -9px
    /*@apply -translate-y-1/2 top-1/2;*/
}

.ak-menu-header-container:hover .ak-admiko-menu-edit, .ak-menu-item:hover .ak-admiko-menu-edit {
  display: flex
}

.ak-admiko-settings {
  /*-translate-x-1/2 */
  position: absolute;
  right: 0px;
  top: 55px;
  z-index: 50;
  margin-right: 0.75rem;
  display: flex;
  height: 2rem;
  width: 2rem;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ak-admiko-settings:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  cursor: pointer
}

@keyframes spin {

  to {
    transform: rotate(360deg)
  }
}

.ak-admiko-settings:hover i {
  animation: spin 8s linear infinite
}

/*.ak-js-classes {
  @apply inline-block;
}*/

/*override template settings*/

/*override template settings*/

/*override template settings*/

/*.ak-menu-dropdown {
  &:hover {
    i {
      @apply animate-spin-slow;
    }
  }
}*/

@keyframes pulse {

  50% {
    opacity: .5
  }
}

.ak-menu-item-loading {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

.ak-menu-item-loading div {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: 0.5rem;
  height: 1rem;
  border-radius: 0.25rem;
  background-color: rgb(96 165 250 / 30%)
}

.ak-form-loading {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

:is(.dark .ak-form-loading) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity))
}

.ak-form-loading {

  /*.admiko-ak-loading{
    @apply text-left py-2;
  }*/
  /*@apply animate-pulse;
  div {
    @apply bg-gray-300/[30%] rounded-xl mx-3 mb-2 h-8;
  }*/
}

.ak-form-loading span {
  padding-right: 0.5rem
}

@keyframes spin {

  to {
    transform: rotate(360deg)
  }
}

.ak-form-loading span i {
  animation: spin 1s linear infinite
}

.ak-page-empty > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
}

.ak-page-empty {
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ak-page-empty span {
  font-weight: 500
}

.ak-form-empty {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity))
}

:is(.dark .ak-form-empty) {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity))
}

.ak-form-empty span {
  font-weight: 500
}

.ak-system-file-info {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity));
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity))
}

:is(.dark .ak-system-file-info) {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity))
}

.ak-system-file-info span {
  font-weight: 500
}

@keyframes pulse {

  50% {
    opacity: .5
  }
}

.ak-content-loading {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

.ak-content-loading div {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: 0.5rem;
  height: 10rem;
  border-radius: 0.5rem;
  background-color: rgb(156 163 175 / 20%)
}

@keyframes pulse {

  50% {
    opacity: .5
  }
}

.ak-header-loading {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

.ak-header-loading div {
  margin-bottom: 0.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  background-color: rgb(156 163 175 / 10%)
}

.ak-fade-in {
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
}

.ak-fade-in.fade-in-start {
  opacity: 1
}

.ak-page-info-container {
  position: relative
}

.ak-builder .ak-container {
  overflow: unset;
}

.ak-builder .ak-container.ak-container-hide-tool {
  overflow-x: hidden
}

.ak-sidebar {
  position: relative
}

.ak-section-content form .ak-section-form-content .sort-me {
  display: flex;
  flex-wrap: wrap
}

/*
.ak-form-container .sort-me .ak-form-group.active {
  @apply w-full;
} */





